import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { Layout, MaxWidthContainer } from "../Layout";
import { EventContext, Header, BrandAd } from "Components";
import { RegistrationContent } from "./RegistrationContent";
import { fonts } from "theme/fonts";

export const Register = React.memo(({ onSuccess }) => {
  const { event } = useContext(EventContext);
  const [cookies, setCookie] = useCookies();

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let desktopImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImage?.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImage?.mobile}?alt=media`;

  const { backgrounds } = event.register;
  const regBg0 = `${api}/o/${storageDirectory}%2F${backgrounds[0]}?alt=media`;
  const regBg1 = `${api}/o/${storageDirectory}%2F${backgrounds[1]}?alt=media`;

  const handleSuccess = (admin, token) => {
    onSuccess(admin, token);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const startDate = event.start.toFormat("LLLL d, yyyy");
  const startTime = event.start.toFormat("h a ZZZZ");

  return (
    <Layout registration flexGrow showFooter>
      <Background background={cookies?.registered ? regBg1 : regBg0}>
        <Header showHeader registration />

        <Registration>
          <ContentWrapper>
            <RegistrationContent handleSuccess={handleSuccess} />
          </ContentWrapper>

          <ArtistImage
            as="section"
            id="artist-image"
            desktopImage={desktopImage}
            mobileImage={mobileImage}
          >
            <OverlayContainer>
              <div>
                <h2>{event.artistName}</h2>
                <h3>
                  <span>{startDate}</span> | <span>{startTime}</span>
                </h3>
              </div>
            </OverlayContainer>
          </ArtistImage>
        </Registration>
      </Background>

      {cookies?.registered && <BrandAd />}
    </Layout>
  );
});

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #755d58;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}
`;

// ======== CONTENT / LEFT SIDE ======== //

const Registration = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    padding: 50px 0;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 15px 50px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px 50px 25px;
    margin: 0;
    width: 50%;
    max-width: 600px;
  }
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const ArtistImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(${(props) => props.mobileImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 92vw;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktopImage});
    flex: 1;
    height: unset;
    margin-top: -100px;
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin-left: 7vw;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin-left: 3vw;
  }

  & > div {
    margin-top: 10vw;

    @media only screen and ${(props) => props.theme.maxMedia.medium} {
      margin-top: 5vw;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  h2 {
    font-size: clamp(36px, 14vw, 144px);
    letter-spacing: 4px;
    line-height: 1;

    @media only screen and ${(props) => props.theme.maxMedia.medium} {
      letter-spacing: 8px;
      font-size: clamp(36px, 6vw, 92px);
    }
  }

  h3 {
    font-size: clamp(16px, 4vw, 72px);
    letter-spacing: 2px;
    line-height: 1;

    @media only screen and ${(props) => props.theme.maxMedia.medium} {
      letter-spacing: 4px;
      font-size: clamp(16px, 1.5vw, 28px);
    }
  }
`;
