import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext } from "Components";
import { Layout } from "../Layout";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);

  return (
    <Layout showHeader preCampaign flexGrow>
      <PageContent>
        <h2>{ReactHtmlParser(event.preCampaign.heading)}</h2>
      </PageContent>
    </Layout>
  );
};

const PageContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: clamp(18px, 9vw, 64px);
    line-height: 1;
    text-transform: uppercase;
    margin: 25px 0 125px;
    color: #80c34f;
  }
`;
