import React from "react";
import styled from "styled-components";
import { P, GridRow, Icon } from "notes";

export const FieldMessage = ({
  content = "",
  type = "error",
  active = true,
  ...props
}) => {
  return (
    active && (
      <ErrorMessageContainer yCenter {...props}>
        {type === "error" && <ErrorIcon form name="Error" />}
        {type === "success" && <SuccessIcon badge name="FacebookVerified" />}
        <Message>{content}</Message>
      </ErrorMessageContainer>
    )
  );
};

const ErrorMessageContainer = styled(GridRow)`
  margin-top: 5px;
  margin-bottom: 12px;
`;

const Message = styled(P)`
  color: #ffffff;
  font-size: 12px;
  margin: 0;
`;

const ErrorIcon = styled(Icon)`
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
  width: 12px;
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const SuccessIcon = styled(ErrorIcon)`
  path {
    fill: ${(props) => props.theme.palette.green.primary};
  }
`;
