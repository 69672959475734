import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Layout, MaxWidthContainer } from "../Layout";
import { EventContext, AnalyticsContext, Header, Footer } from "Components";
import { fonts } from "theme/fonts";

export const Home = () => {
  const { event } = useContext(EventContext);
  const { logClick, logLeadSubmission } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let desktopImage = `${api}/o/${storageDirectory}%2F${event?.artistImage?.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${event?.artistImage?.mobile}?alt=media`;

  const pageHeading = event.heading;

  const {
    body,
    btnLink,
    btnText,
    couponInfo,
    downloadButtons,
    heading,
    productIcons,
    productImage,
    subHeading,
  } = event.callToActions.brand;

  let productImageDesktop = `${api}/o/${storageDirectory}%2F${productImage.desktop}?alt=media`;
  let productImageMobile = `${api}/o/${storageDirectory}%2F${productImage.mobile}?alt=media`;
  let productIconsImg = `${api}/o/${storageDirectory}%2F${productIcons}?alt=media`;

  const downloadClickHandler = (link) => {
    logClick({
      label: "Download App Button",
      url: link,
    });
  };

  const ctaClickHandler = () => {
    logLeadSubmission({
      formPart: 0,
      registration: {},
    });
  };

  const artistName = event.artist;

  return (
    <Layout>
      <Wrapper>
        <div className="div1">
          <Header showHeader />
        </div>

        <PageHeading className="div2">
          <PageHeadingContainer>
            <h1>{ReactHtmlParser(pageHeading)}</h1>
          </PageHeadingContainer>
        </PageHeading>

        <Artist
          className="div3"
          desktopImg={desktopImage}
          mobileImg={mobileImage}
        >
          <div>
            <h6>{artistName.replace(" - Test", "")}</h6>
            <p>
              <em>{event.artistTitle}</em>
            </p>
          </div>
        </Artist>

        <BrandCTA className="div4">
          <BrandCTAContainer>
            <picture>
              <source srcset={productImageDesktop} media="(min-width: 950px)" />
              <img src={productImageMobile} />
            </picture>

            <Body>{ReactHtmlParser(body)}</Body>

            <ProductIcons src={productIconsImg} />

            <CTABtn
              href={btnLink}
              target="_blank"
              onClick={() => ctaClickHandler(btnText, btnLink)}
            >
              {ReactHtmlParser(btnText)}
            </CTABtn>

            <Coupon>
              <h2>{ReactHtmlParser(heading)}</h2>
              <h3>{ReactHtmlParser(subHeading)}</h3>

              <DownloadButtons>
                {downloadButtons.map((item, idx) => {
                  let buttonImage = `${api}/o/${storageDirectory}%2F${item.image}?alt=media`;
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      onClick={() => downloadClickHandler(item.link)}
                      key={idx}
                    >
                      <img src={buttonImage} />
                    </a>
                  );
                })}
              </DownloadButtons>

              <p>{ReactHtmlParser(couponInfo)}</p>
            </Coupon>
          </BrandCTAContainer>
        </BrandCTA>

        <div className="div5">
          <Footer showFooter />
        </div>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 190vw auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div4 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .div5 {
    grid-area: 5 / 1 / 6 / 2;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    grid-template-columns: 592px auto;
    grid-template-rows: auto 250px 1fr auto;

    .div1 {
      grid-area: 1 / 1 / 2 / 3;
    }
    .div2 {
      grid-area: 2 / 1 / 3 / 2;
    }
    .div3 {
      grid-area: 2 / 2 / 5 / 3;
    }
    .div4 {
      grid-area: 3 / 1 / 5 / 2;
    }
    .div5 {
      grid-area: 4 / 2 / 5 / 3;
    }
  }
`;

const PageHeading = styled.div`
  h1 {
    color: #80c34f;
    text-transform: uppercase;
    line-height: 1;
    font-size: 48px;
    margin: 0;

    span {
      display: block;
    }

    span:last-of-type {
      font-weight: 500;
    }
  }
`;

const Artist = styled.div`
  background-image: url(${(props) => props.mobileImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktopImg});
    background-position: right;
  }

  div {
    background-color: #83c453;
    color: #ffffff;
    padding: 15px 75px 15px 25px;
    margin-bottom: 50px;

    @media ${(props) => props.theme.maxMedia.medium} {
      margin-bottom: 200px;
    }
  }

  h6 {
    font-family: ${fonts.default};
    margin: 0;
    font-size: 20px;
  }

  p {
    margin: 0;
  }
`;

const BrandCTA = styled.div`
  color: #ffffff;

  picture {
    width: 100%;
    margin-top: -29vw;
    display: inline-block;
    margin-bottom: 15px;

    @media ${(props) => props.theme.maxMedia.medium} {
      margin-top: -90px;
      margin-bottom: 0;
    }
  }
`;

const Body = styled.p`
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  max-width: 475px;
  margin: 0 auto 25px;
  font-family: ${fonts.header};
`;

const CTABtn = styled.a`
  background-color: #ffffff;
  color: #80c34f;
  text-transform: uppercase;
  padding: 10px 50px;
  text-decoration: none;
  font-size: 34px;
  font-weight: 600;
  font-family: ${fonts.header};
  margin-bottom: 25px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
    color: #ffffff;
  }
`;

const Coupon = styled.div`
  width: 100%;

  h2 {
    font-size: 52px;
    text-transform: uppercase;
    line-height: 1.15;
    text-align: center;
    margin-bottom: 16px;

    span {
      display: block;
    }

    span:nth-of-type(2) {
      font-size: 16px;
      font-weight: 600;
    }
  }

  h3 {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    margin: 0 auto 24px;
    width: 100%;
    max-width: 250px;
  }

  p {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    font-family: ${fonts.header};

    a,
    a:visited {
      color: #ffffff;
      text-decoration: none;
    }
  }
`;

const DownloadButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    width: 50%;
    max-width: 175px;
    margin-right: 15px;

    &:first-of-type {
      padding: 0 2px;
    }

    &:last-of-type {
      margin: 0;
    }

    img {
      display: block;
    }
  }
`;

const ProductIcons = styled.img`
  width: 100%;
  max-width: 325px;
  margin-bottom: 25px;
`;

const Container = styled.div`
  padding: 0 15px;

  img {
    width: 100%;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px;
  }
`;

const PageHeadingContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 0 0 50px;
    justify-content: start;
  }
`;

const BrandCTAContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 33vw;
  padding: 0 15px 35px 15px;
  background-color: #83c453;

  @media ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
    height: 100%;
    padding: 0 25px 50px 25px;
  }
`;
