const brand = {
  regular: "interstate", // 500
  condensed: "interstate-condensed", // 500, 500i, 700, 800
};

const base = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
const emoji = "MaxEmoji";

const fallbacks = {
  default: `Roboto, ${base}, ${emoji}`,
  header: `Montserrat, ${base}, ${emoji}`,
};

export const fonts = {
  brand,
  default: `${brand.regular}, ${fallbacks.default}`,
  header: `${brand.condensed}, ${fallbacks.header}`,
};
