import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import {
  EventContext,
  TimeContext,
  CountdownSmall as Countdown,
  HubPageCTA,
} from "Components";
import { RegistrationForm } from "./RegistrationForm";
import { fonts } from "theme/fonts";

export const RegistrationContent = ({ handleSuccess }) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const [cookies, setCookie] = useCookies();

  let RegFormContent;
  let showCountdown = null;
  let showHubCTA = null;

  const countdownHeading = event.preShow.countdownLabel;

  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: countdownHeading ? countdownHeading : "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  if (event.campaignEnd < time) {
    // campaign has ended
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(event.postCampaign.heading)}</Heading>
        <Body>{ReactHtmlParser(event.postCampaign.body)}</Body>
      </>
    );
  } else if (event.endSweeps > time) {
    const { heading, submit } = event.register.sweeps[0];
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(heading)}</Heading>
        <RegistrationForm
          formPart={0}
          onSuccess={handleSuccess}
          submitButton={submit}
        />
      </>
    );

    if (cookies?.registered) {
      const { heading } = event.register.sweeps[1];
      RegFormContent = (
        <ConfirmationHeading>{ReactHtmlParser(heading)}</ConfirmationHeading>
      );
      showCountdown = true;
    }
  } else {
    const { heading, submit } = event.register.postSweeps[0];
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(heading)}</Heading>
        <RegistrationForm
          formPart={0}
          onSuccess={handleSuccess}
          submitButton={submit}
        />
      </>
    );

    if (cookies?.registered) {
      const {
        heading,
        body,
        showHubCTA: showHubLink,
      } = event.register.postSweeps[1];
      RegFormContent = (
        <>
          <ConfirmationHeading>{ReactHtmlParser(heading)}</ConfirmationHeading>
          {body && <ConfirmationBody>{ReactHtmlParser(body)}</ConfirmationBody>}
        </>
      );
      showCountdown = true;
      showHubCTA = showHubLink;
    }
  }

  return (
    <>
      <ContentContainer>
        {RegFormContent}

        {showCountdown && (
          <SmallCountdown>
            <Countdown {...timer} />
          </SmallCountdown>
        )}

        {showHubCTA && <HubPageCTA />}
      </ContentContainer>
    </>
  );
};

const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  p {
    margin-top: 0;
  }

  a {
    color: #ffffff;
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 16px;
  color: #ffffff;
`;

const SubHeading = styled.h3`
  font-size: 34px;
  margin: 0;
`;

const Body = styled.p`
  color: #ffffff;
`;

const ConfirmationHeading = styled(Heading)`
  font-size: clamp(36px, 10vw, 58px);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const ConfirmationBody = styled(Body)``;

const SmallCountdown = styled.div`
  margin-bottom: 32px;
`;
