import React, { useState, useReducer, useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {
  FormMessage,
  handleErrors,
  firebase,
  EventContext,
  AnalyticsContext,
  Field,
} from "Components";
import { reducer } from "Modules";
import { GridColumn, GridRow, Label } from "notes";
import { fonts } from "theme/fonts";
import { DateTime } from "luxon";

export const RegistrationForm = ({
  formPart,
  formLegal,
  onSuccess,
  submitButton,
}) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { event } = useContext(EventContext);
  const { logLeadSubmission, setUserProperties } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();
  const [errorMessages, setErrorMessages] = useState({});

  const ageGateMessage = event.register.ageGateMessage;
  const formHeading = event.register.formHeading;
  const fields = event.register.fields;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration, formPart);
    const errorMsgs = {};

    if (!errorObject) errorObject = {};

    const month = registration["DOB"] && registration["DOB"]["MONTH"];
    const day = registration["DOB"] && registration["DOB"]["DAY"];
    const year = registration["DOB"] && registration["DOB"]["YEAR"];

    const MIN_DATE = DateTime.utc().minus({ years: 120 });
    const MAX_DATE = DateTime.utc().minus({ years: 21 });

    if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
      const date = DateTime.fromObject({ year, month, day, zone: "utc" });
      const valid = MIN_DATE <= date && date <= MAX_DATE;

      if (!valid) {
        errorObject.DOB = true;
        errorMsgs["DOB"] = "Please enter valid date. Must be 21+";
      }

      if (year.length <= 3 || month.length <= 1 || day.length <= 1) {
        errorObject.DOB = true;
        errorMsgs["DOB"] = "Please use correct date formats.";
      }
    }

    setErrorMessages(errorMsgs);

    if (!Object.keys(errorObject).length) {
      setSubmitting(true);
      try {
        firebase
          .firestore()
          .collection(`events/${process.env.REACT_APP_EVENT_ID}/lead_submits`)
          .add({
            ...registration,
            email: registration.EMAIL,
          })
          .then((ref) => {
            ref.onSnapshot((doc) => {
              const { token } = doc.data();
              if (token) {
                onSuccess(false, token, formPart);
                setSubmitting(false);
                logLeadSubmission({ formPart, registration });
                setUserProperties({ token });
              }
            });
          });
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{formHeading}</FormTitle>}

      <Form>
        {fields &&
          fields
            .sort((a, b) => a.order - b.order)
            .filter((field) => {
              return field.formPart === formPart;
            })
            .map((field, index) => {
              let mobileSize = "100%";
              let desktopSize = "100%";
              if (field.span === "half") desktopSize = "50%";

              if (
                field.span !== "full" &&
                (field.spanMobile === "half" || !field.spanMobile)
              )
                mobileSize = "50%";

              return (
                <FieldContainer
                  sizeDesktop={desktopSize}
                  sizeMobile={mobileSize}
                  fullSize={field.span === "full"}
                  key={index}
                  order={field.order}
                >
                  {field.type !== "optIn" && !!field.label && (
                    <Label>{field.label}</Label>
                  )}
                  <Field
                    field={field}
                    fieldCheck={
                      !!field.requires ? registration[field.requires] : true
                    }
                    value={registration[field.name]}
                    onChange={(value) => handleChange(value, field.name)}
                    error={{
                      status: errors[field.name],
                      message: errorMessages[field.name]
                        ? errorMessages[field.name]
                        : "This is a required field and cannot be blank.",
                    }}
                  />
                </FieldContainer>
              );
            })}

        {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}
      </Form>

      <FormMessage
        active={!!errors?.AGE_GATE}
        content={ageGateMessage}
        style={{ order: 6 }}
      />

      {submitting ? (
        <RegisterButton disabled>Loading</RegisterButton>
      ) : (
        <RegisterButton onClick={handleSubmit}>
          {submitButton ? submitButton : "Submit"}
        </RegisterButton>
      )}
    </>
  );
};

const FormTitle = styled.h3`
  color: #ffffff;
`;

const Form = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => props.sizeMobile};
  padding: 8px 4px 0 4px;
  width: ${(props) => props.sizeMobile};
  ${(props) => props.order && `order: ${props.order};`}

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 0 1 ${(props) => props.sizeDesktop};
    width: ${(props) => props.sizeDesktop};
  }
`;

const FormLegal = styled.div`
  color: #ffffff;
  font-size: 11px;
  line-height: 1.5;
  margin: 7px 0;
  padding: 8px 4px 0 4px;
  font-style: italic;
  order: 4;

  a,
  a:visited {
    color: #ffffff;
  }
`;

const RegisterButton = styled.button`
  margin-top: 24px;
  background-color: #fec82e;
  color: #ffffff;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  outline: 0;
  border: 0;
  border-radius: 5px;
  font-family: ${fonts.header};
  width: fit-content;
  letter-spacing: 2px;

  &:hover,
  &:focus {
    background-color: #ebaa00;
  }
`;
