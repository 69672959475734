import React, { forwardRef } from "react";
import styled from "styled-components";
import { fonts } from "../../theme/fonts";

export const Input = forwardRef((props, ref) =>
  (({
    onChange,
    value,
    placeholder,
    error,
    disabled,
    InternalField = TextField,
    placeholderStyle,
    ageGate,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { value },
      } = e;
      return onChange(value, e);
    };
    return (
      <TextContainer error={error} disabled={disabled}>
        <Placeholder error={error} visible={value} ageGate={ageGate}>
          {placeholder}
        </Placeholder>
        <InternalField
          onChange={handleChange}
          value={value}
          error={error}
          disabled={disabled}
          ageGate={ageGate}
          {...props}
        />
      </TextContainer>
    );
  })({ ...props, ref })
);

const inputPadding = "10px 12px";
const borderRadius = "5px";

const errorBackground = "#ff8585";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#6d2621";

const TextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: ${borderRadius};
  overflow: hidden;
  background-color: ${(props) => (props.disabled ? "#A6AEB2" : "#ffffff")};
  ${(props) => props.error && `background-color: ${errorBackground};`}
  text-transform: uppercase;
`;

const Placeholder = styled.div`
  color: ${(props) => (props.ageGate ? "#031689" : "#C6C6C6")};
  display: ${(props) => (props.visible ? "none" : "flex")};
  padding: ${inputPadding};
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  border-radius: ${borderRadius};
  width: 100%;
  ${(props) =>
    props.ageGate &&
    `
    text-align: center;
    justify-content: center;
  `};
  ${(props) => props.error && `color: ${errorFieldColor}`};
`;

const TextField = styled.input`
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: ${borderRadius};
  color: ${(props) => (props.ageGate ? "#031689" : "#824d2e")};
  display: block;
  outline: 0;
  padding: ${inputPadding};
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  ${(props) => props.ageGate && `text-align: center;`};

  &:focus {
    border: 1px solid #ffffff;
  }
  &:disabled {
    border: 1px solid #626466;
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid ${errorBorderColor};

    &:focus {
      border: 2px solid ${errorBorderColor};
    }
  `};
`;
