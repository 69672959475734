import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "Components";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick, logLeadSubmission } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand: brandLogo, coBrand: coBrandLogo } = event.logos;

  let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo.image}?alt=media`;
  let coBrandLogoImg = `${api}/o/${storageDirectory}%2F${coBrandLogo.image}?alt=media`;

  const { heading: ctaHeading, link: ctaLink } = event.callToActions.coBrand;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  const ctaClickHandler = () => {
    logLeadSubmission({
      formPart: 0,
      registration: {},
    });
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer>
            <Logos>
              <LogoBox>
                <Link
                  href={brandLogo.link}
                  target="_blank"
                  onClick={() => logoClickHandler(brandLogo.link)}
                >
                  <PrimaryLogo src={brandLogoImg} />
                </Link>
              </LogoBox>

              <LogoBox>
                <Link
                  href={coBrandLogo.link}
                  target="_blank"
                  onClick={() => logoClickHandler(coBrandLogo.link)}
                >
                  <SecondaryLogo src={coBrandLogoImg} />
                </Link>
              </LogoBox>
            </Logos>

            {!preCampaign && (
              <CoBrandCTA>
                <a href={ctaLink} target="_blank" onClick={ctaClickHandler}>
                  {ReactHtmlParser(ctaHeading)}
                </a>
              </CoBrandCTA>
            )}
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 15px 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  @media ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
  }
`;

const LogoBox = styled.div`
  border-right: 1px solid #afafaf;
  padding-right: 15px;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding-right: 25px;
  }

  &:last-of-type {
    border: 0;
    padding: 0 0 0 15px;

    @media ${(props) => props.theme.maxMedia.medium} {
      padding: 0 0 0 25px;
    }
  }
`;

const Link = styled.a`
  display: flex;
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)`
  max-width: 150px;

  @media ${(props) => props.theme.maxMedia.medium} {
    max-width: 175px;
  }
`;

const SecondaryLogo = styled(Logo)`
  max-width: 50px;

  @media ${(props) => props.theme.maxMedia.medium} {
    max-width: 60px;
  }
`;

const CoBrandCTA = styled.h3`
  color: #e80018;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.15;

  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: 20px;
    margin: 0;
  }

  span {
    font-weight: 500;
  }

  a {
    color: #e80018;
    text-decoration: none;
  }
`;
