import React, { useContext } from "react";
import { EventContext, TimeContext } from "Components";
import { PreCampaign, Home } from "./Components";

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  if (event.campaignStart > time) return <PreCampaign />;
  return <Home />;
};
