import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "Components";
import { fonts } from "theme/fonts";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

export const Footer = ({
  showFooter,
  registration,
  preCampaign,
  registered,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand: brandLogo, coBrand: coBrandLogo } = event.logos;

  let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo.image}?alt=media`;
  let coBrandLogoImg = `${api}/o/${storageDirectory}%2F${coBrandLogo.image}?alt=media`;

  const footerMenu = event.footer.menu;
  const showPrimaryLogo = false;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer">
          <FooterContainer>
            {showPrimaryLogo && (
              <Link
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => logoClickHandler(brandLogo.link)}
              >
                <Logo src={brandLogoImg} />
              </Link>
            )}

            {event.footer.brandDisclaimer && (
              <BrandDisclaimer>
                {ReactHtmlParser(event.footer.brandDisclaimer)}
              </BrandDisclaimer>
            )}

            {registration && (
              <SweepsDisclaimer>
                {!cookies?.registered && (
                  <>
                    {event.endSweeps > time &&
                      event.footer.sweepsDisclaimer &&
                      ReactHtmlParser(event.footer.sweepsDisclaimer)}
                    {event.footer.grandPrizeDisclaimer &&
                      ReactHtmlParser(event.footer.grandPrizeDisclaimer)}
                  </>
                )}
              </SweepsDisclaimer>
            )}

            {footerMenu && (
              <FooterMenu>
                {footerMenu.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => footerMenuClickHandler(item.link)}
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </FooterMenu>
            )}

            <MaxPolicy>{ReactHtmlParser(event.footer.maxPolicy)}</MaxPolicy>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #ffffff;
  background-color: #9c968e;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-color: transparent;
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 25px 15px;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px;
  }
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 100%;
  max-width: 250px;
`;

const SweepsDisclaimer = styled.div`
  a,
  a:visited {
    color: #ffffff;
  }
`;

const MaxPolicy = styled.p`
  color: #ffffff;

  a,
  a:visited {
    color: #ffffff;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    color: #ffffff;

    a,
    a:visited {
      color: #ffffff;
    }
  }
`;

const BrandDisclaimer = styled.div``;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 8px;
  }

  a,
  a:visited {
    color: #ffffff;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;

    a,
    a:visited {
      color: #ffffff;
    }
  }
`;
